body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url("https://www.pikpng.com/pngl/b/328-3283393_rainbow-png-image-rainbow-clipart-free-download-.png");
    background-size: cover;
    background-repeat: no-repeat;
}
